<template>
  <v-container
    fluid
    class="white-background client-service-forms service-report-form"
    :class="{ 'pointer-events-none': serviceForm.is_filled }"
  >
    <v-row>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Customer Name
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.customer_name"
                  dense
                  filled
                  label="Customer Name"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Site Location
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.site_location"
                  dense
                  filled
                  label="Site Location"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Site Address
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.site_address"
                  dense
                  filled
                  label="Site Address"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Contact Name
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.contact_name"
                  dense
                  filled
                  label="Contact Name"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Contact No
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.contact_no"
                  dense
                  filled
                  label="Contact No"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">Date</td>
              <td>
                <DatePicker
                  v-model="formData.date"
                  solo
                  :defaultDate.sync="formData.date"
                ></DatePicker>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Maintenance Plan
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.maintenance_plan"
                  dense
                  filled
                  label="Maintenance Plan"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Equipment ID
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.equipment_id"
                  dense
                  filled
                  label="Equipment ID"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Equipment Model
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.equipment_model"
                  dense
                  filled
                  label="Equipment Model"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Frame Serial No
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.frame_serial_no"
                  dense
                  filled
                  label="Frame Serial No"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td
                class="font-weight-500 font-size-16 p-2"
                width="500"
                align="left"
              >
                Input
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">U</td>
              <td class="font-weight-500 font-size-16 p-2" align="center">V</td>
              <td class="font-weight-500 font-size-16 p-2" align="center">W</td>
            </tr>
            <tr>
              <td
                class="font-weight-500 font-size-16 p-2"
                width="500"
                align="left"
              >
                Voltage (V)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.voltage.u"
                  dense
                  filled
                  label="U"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.voltage.v"
                  dense
                  filled
                  label="V"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.voltage.w"
                  dense
                  filled
                  label="W"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td
                class="font-weight-500 font-size-16 p-2"
                width="500"
                align="left"
              >
                Current (A)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.current.u"
                  dense
                  filled
                  label="U"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.current.v"
                  dense
                  filled
                  label="V"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.current.w"
                  dense
                  filled
                  label="W"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td
                class="font-weight-500 font-size-16 p-2"
                width="500"
                align="left"
              >
                Real Power (kW)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.real_power.u"
                  dense
                  filled
                  label="U"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.real_power.v"
                  dense
                  filled
                  label="V"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.real_power.w"
                  dense
                  filled
                  label="W"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td
                class="font-weight-500 font-size-16 p-2"
                width="500"
                align="left"
              >
                Apparent Power (kVA)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.apparent_power.u"
                  dense
                  filled
                  label="U"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.apparent_power.v"
                  dense
                  filled
                  label="V"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.apparent_power.w"
                  dense
                  filled
                  label="W"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td
                class="font-weight-500 font-size-16 p-2"
                width="500"
                align="left"
              >
                Frequency (Hz)
              </td>
              <td colspan="3" class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.input.frequency.value"
                  dense
                  filled
                  label="Frequency (Hz)"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">Load</td>
              <td class="font-weight-500 font-size-16 p-2" align="center">U</td>
              <td class="font-weight-500 font-size-16 p-2" align="center">V</td>
              <td class="font-weight-500 font-size-16 p-2" align="center">W</td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Voltage (V)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.voltage.u"
                  dense
                  filled
                  label="U"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.voltage.v"
                  dense
                  filled
                  label="V"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.voltage.w"
                  dense
                  filled
                  label="W"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Current (A)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.current.u"
                  dense
                  filled
                  label="U"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.current.v"
                  dense
                  filled
                  label="V"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.current.w"
                  dense
                  filled
                  label="W"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Real Power (kW)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.real_power.u"
                  dense
                  filled
                  label="U"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.real_power.v"
                  dense
                  filled
                  label="V"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.real_power.w"
                  dense
                  filled
                  label="W"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Apparent Power (kVA)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.apparent_power.u"
                  dense
                  filled
                  label="U"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.apparent_power.v"
                  dense
                  filled
                  label="V"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.apparent_power.w"
                  dense
                  filled
                  label="W"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Power Factor (PF)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.power_factor.u"
                  dense
                  filled
                  label="U"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.power_factor.v"
                  dense
                  filled
                  label="V"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.power_factor.w"
                  dense
                  filled
                  label="W"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Percentage (%)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.percentage.u"
                  dense
                  filled
                  label="U"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.percentage.v"
                  dense
                  filled
                  label="V"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.load.percentage.w"
                  dense
                  filled
                  label="W"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr>
              <td
                class="light-gray-background font-weight-500 font-size-16 p-2"
                width="250"
              >
                Rectifier temp A1
              </td>
              <td class="font-weight-600 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.rectifier_temp_a1.one"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-600 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.rectifier_temp_a1.two"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-600 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.rectifier_temp_a1.three"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td
                class="light-gray-background font-weight-500 font-size-16 p-2"
                width="250"
              >
                Rectifier temp A2
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.rectifier_temp_a2.one"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.rectifier_temp_a2.two"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.rectifier_temp_a2.three"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td
                class="light-gray-background font-weight-500 font-size-16 p-2"
                width="250"
              >
                Inverter temp A1
              </td>
              <td class="font-weight-600 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.inverter_temp_a1.one"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-600 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.inverter_temp_a1.two"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-600 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.inverter_temp_a1.three"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td
                class="light-gray-background font-weight-500 font-size-16 p-2"
                width="250"
              >
                Inverter temp A2
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.inverter_temp_a2.one"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.inverter_temp_a2.two"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.temperature.inverter_temp_a2.three"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">
                PM 1 serial number
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.pm_1_serial_number.pm_1_serial_number"
                  dense
                  filled
                  label="PM 1 serial number"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Manufacturing date
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.pm_1_serial_number.manufacturing_date"
                  dense
                  filled
                  label="Manufacturing date"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Module EPROM Version
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="
                    formData.pm_1_serial_number.rectifier_dsp_firmware
                  "
                  dense
                  filled
                  label="Module EPROM Version"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                INVERTER DSP firmware
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="
                    formData.pm_1_serial_number.inverter_dsp_firmware
                  "
                  dense
                  filled
                  label="INVERTER DSP firmware"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Battery Bank
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.battery_bank.battery_bank"
                  dense
                  filled
                  label="Battery Bank"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Battery Model
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.battery_bank.battery_model"
                  dense
                  filled
                  label="Battery Model"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                No of Block
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.battery_bank.no_of_block"
                  dense
                  filled
                  label="No of Block"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                No of Bank
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.battery_bank.no_of_bank"
                  dense
                  filled
                  label="No of Bank"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Charger/Booster
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.charger_booster.charger_booster"
                  dense
                  filled
                  label="Charger/Booster"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Output Volt (Vdc)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.charger_booster.output_volt"
                  dense
                  filled
                  label="Output Volt (Vdc)"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Battery Volt (Vdc)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.charger_booster.battery_volt"
                  dense
                  filled
                  label="Battery Volt (Vdc)"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Battery current (A)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.charger_booster.battery_current"
                  dense
                  filled
                  label="Battery current (A)"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Battery
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.battery.battery"
                  dense
                  filled
                  label="Battery"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Battery Voltages (Vdc)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.battery.battery_voltages"
                  dense
                  filled
                  label="Battery Voltages (Vdc)"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                DC Ripple (mV)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.battery.dc_ripple"
                  dense
                  filled
                  label="DC Ripple (mV)"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                DC Ripple Amp (mA)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="formData.battery.dc_ripple_amp"
                  dense
                  filled
                  label="DC Ripple Amp (mA)"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr class="light-gray-background">
              <td
                class="font-weight-500 font-size-16 p-2"
                align="center"
                colspan="3"
              >
                Physical Check List v Normal / x Follow-up with require
              </td>
            </tr>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="60">Item</td>
              <td class="font-weight-500 font-size-16 p-2">Descriptions</td>
              <td class="font-weight-500 font-size-16 p-2">Status</td>
            </tr>
            <tr
              v-for="(row, index) in formData.physical_check_list_left"
              :key="index"
            >
              <td class="font-weight-500 font-size-16 p-2" width="60">
                {{ row.item }}
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="row.description"
                  dense
                  filled
                  label="Descriptions"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-checkbox
                  dense
                  v-model="row.status"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  hide-details
                  class="m-0 mx-4"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr class="light-gray-background">
              <td
                class="font-weight-500 font-size-16 p-2"
                align="center"
                colspan="3"
              >
                Physical Check List v Normal / x Follow-up with require
              </td>
            </tr>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="60">Item</td>
              <td class="font-weight-500 font-size-16 p-2">Descriptions</td>
              <td class="font-weight-500 font-size-16 p-2">Status</td>
            </tr>
            <tr
              v-for="(row, index) in formData.physical_check_list_right"
              :key="index"
            >
              <td class="font-weight-500 font-size-16 p-2" width="60">
                {{ row.item }}
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model.trim="row.description"
                  dense
                  filled
                  label="Descriptions"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-checkbox
                  dense
                  v-model="row.status"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  hide-details
                  class="m-0 mx-4"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="12">
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2">Remarks</td>
            </tr>
            <tr>
              <td>
                <v-textarea
                  v-model.trim="formData.remarks_and_parts_replacement"
                  auto-grow
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  dense
                  filled
                  color="cyan"
                  label="Remarks"
                  solo
                  flat
                  row-height="25"
                ></v-textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2">
                Comments and Recommendation
              </td>
            </tr>
            <tr>
              <td>
                <v-textarea
                  v-model.trim="formData.comments_and_recommendation"
                  auto-grow
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  dense
                  filled
                  color="cyan"
                  label="Comments and Recommendation"
                  solo
                  flat
                  row-height="25"
                ></v-textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col v-if="false" class="py-0" md="6">
        <table class="width-100 mt-8">
          <tbody>
            <tr>
              <td height="100" colspan="2" id="engineer-signature">
                <div class="engineer-signature custom-border-grey-dashed">
                  <canvas ref="engineersignature"></canvas>
                </div>
                <p class="mt-2 mx-2 mb-0 text-right">
                  <v-btn
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-left"
                    v-on:click="engineerSignature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  Service Engineer Name/Signature
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col v-if="false" class="py-0" md="6">
        <table class="width-100 mt-8">
          <tbody>
            <tr>
              <td height="100" colspan="2" id="customer-signature">
                <div class="customer-signature custom-border-grey-dashed">
                  <canvas ref="customersignature"></canvas>
                </div>
                <p class="mt-2 mx-2 mb-0 text-right">
                  <v-btn
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-left"
                    v-on:click="customerSignature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  Customer Name/Signature
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "@/view/pages/partials/Datepicker";
/*import SignaturePad from "signature_pad";*/
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH, QUERY } from "@/core/services/store/request.module";
import ObjectPath from "object-path";

export default {
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    contract: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    }
  },
  data: () => {
    return {
      pageLoading: true,
      svfield: "control_version",
      svfields: [
        {
          text: "Control Version",
          value: "control_version"
        },
        {
          text: "Module EPROM Version",
          value: "module_eprom_version"
        },
        {
          text: "Rectifier DSP Firmware",
          value: "rectifier_dsp_firmware"
        }
      ],
      engineerSignature: new Object(),
      customerSignature: new Object(),
      formData: new Object({
        barcode: null,
        customer_name: null,
        site_location: null,
        site_address: null,
        contact_name: null,
        contact_no: null,
        date: null,
        maintenance_plan: null,
        equipment_id: null,
        equipment_model: null,
        frame_serial_no: null,
        remarks_and_parts_replacement: null,
        comments_and_recommendation: null,
        customer_signature: null,
        engineer_signature: null,
        input: {
          voltage: {
            u: null,
            v: null,
            w: null
          },
          current: {
            u: null,
            v: null,
            w: null
          },
          real_power: {
            u: null,
            v: null,
            w: null
          },
          apparent_power: {
            u: null,
            v: null,
            w: null
          },
          frequency: {
            value: null
          }
        },
        load: {
          voltage: {
            u: null,
            v: null,
            w: null
          },
          current: {
            u: null,
            v: null,
            w: null
          },
          real_power: {
            u: null,
            v: null,
            w: null
          },
          apparent_power: {
            u: null,
            v: null,
            w: null
          },
          power_factor: {
            u: null,
            v: null,
            w: null
          },
          percentage: {
            u: null,
            v: null,
            w: null
          }
        },
        temperature: {
          rectifier_temp_a1: {
            one: null,
            two: null,
            three: null
          },
          rectifier_temp_a2: {
            one: null,
            two: null,
            three: null
          },
          inverter_temp_a1: {
            one: null,
            two: null,
            three: null
          },
          inverter_temp_a2: {
            one: null,
            two: null,
            three: null
          }
        },
        pm_1_serial_number: {
          pm_1_serial_number: null,
          manufacturing_date: null,
          rectifier_dsp_firmware: null,
          inverter_dsp_firmware: null
        },
        battery_bank: {
          battery_bank: null,
          battery_model: null,
          no_of_block: null,
          no_of_bank: null
        },
        charger_booster: {
          charger_booster: null,
          output_volt: null,
          battery_volt: null,
          battery_current: null
        },
        battery: {
          battery: null,
          battery_voltages: null,
          dc_ripple: null,
          dc_ripple_amp: null
        },
        physical_check_list_left: [
          {
            item: "a.",
            description: "Display Screen",
            status: null
          },
          {
            item: "b.",
            description: "Axial/Blower Fan",
            status: null
          },
          {
            item: "c.",
            description: "Capacitor",
            status: null
          },
          {
            item: "d.",
            description: "PCB Board",
            status: null
          }
        ],
        physical_check_list_right: [
          {
            item: "e.",
            description: "Physical unit",
            status: null
          },
          {
            item: "f.",
            description: "Others, if any",
            status: null
          },
          {
            item: "g.",
            description: null,
            status: null
          },
          {
            item: "h.",
            description: null,
            status: null
          }
        ]
      })
    };
  },
  components: {
    DatePicker
  },
  methods: {
    updatePreInformation() {
      const _this = this;
      try {
        if (_this.lodash.isEmpty(_this.customer) === false) {
          _this.formData.customer_name = _this.customer.company_name;
        }
        if (_this.lodash.isEmpty(_this.property) === false) {
          _this.formData.site_address =
            _this.property.unit_no + " " + _this.property.street_1;
          _this.formData.site_location =
            _this.property.street_2 + " " + _this.property.zip_code;
        }
        if (_this.lodash.isEmpty(_this.detail) === false) {
          _this.formData.contact_name = _this.detail.attention;
        }
        if (_this.lodash.isEmpty(_this.contactPerson) === false) {
          _this.formData.contact_no = _this.contactPerson.primary_phone;
        }
        if (_this.lodash.isEmpty(_this.serviceForm) === false) {
          _this.formData.barcode = _this.serviceForm.barcode;
          if (_this.lodash.isEmpty(_this.serviceForm.attributes) === false) {
            _this.formData = _this.serviceForm.attributes;

            /*if (
              _this.formData.engineer_signature
            ) {
              _this.engineerSignature.fromDataURL(
                _this.formData.engineer_signature
              );
            }
            if (
              _this.formData.customer_signature
            ) {
              _this.customerSignature.fromDataURL(
                _this.formData.customer_signature
              );
            }*/
          }
        }
        _this.pageLoading = false;
      } catch (error) {
        _this.pageLoading = false;
      }
    },
    saveServiceForm(action, product) {
      const _this = this;

      ServiceFormEventBus.$emit("start:loader");

      let param = _this.formData;

      /*if (_this.engineerSignature) {
        param.engineer_signature = _this.engineerSignature.toDataURL();
      }
      if (_this.customerSignature) {
        param.customer_signature = _this.customerSignature.toDataURL();
      }*/

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: param, action, product }
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    },
    getCustomerServiceLabel(value) {
      const { text } = this.lodash.find(this.svfields, { value });
      return text;
    },
    getCustomerServiceForm() {
      const _this = this;
      const { customer } = this.customer;
      _this.$store
        .dispatch(QUERY, {
          url: "customer/service-forms",
          data: { customer }
        })
        .then(({ data }) => {
          const { customer_service_forms } = data;
          const serviceform = _this.lodash.find(customer_service_forms, {
            id: 4
          });
          _this.svfield = serviceform.field;
        })
        .catch(error => {
          _this.logError(error);
        });
    }
  },
  beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
    ServiceFormEventBus.$off("update:service-form-product");
  },
  mounted() {
    const _this = this;
    _this.getCustomerServiceForm();
    ServiceFormEventBus.$on("start:save-service-form", (param, product) => {
      _this.saveServiceForm(param, product);
    });

    ServiceFormEventBus.$on("update:service-form-product", param => {
      _this.formData.maintenance_plan = ObjectPath.get(
        _this.contract,
        "contract_type"
      );
      _this.formData.equipment_id = ObjectPath.get(param, "name");
      _this.formData.equipment_model = ObjectPath.get(param, "brand");
      _this.formData.pm_1_serial_number.pm_1_serial_number = ObjectPath.get(
        param,
        "pm_one_serial_number"
      );
      _this.formData.pm_1_serial_number.manufacturing_date = ObjectPath.get(
        param,
        "manufacturing_date"
      );
      _this.formData.pm_1_serial_number.rectifier_dsp_firmware = ObjectPath.get(
        param,
        "rectifier_dsp_firmware"
      );
      _this.formData.pm_1_serial_number.inverter_dsp_firmware = ObjectPath.get(
        param,
        "inverter_dsp_firmware"
      );
      _this.formData.battery_bank.battery_bank = ObjectPath.get(
        param,
        "battery_bank"
      );
      _this.formData.battery_bank.battery_model = ObjectPath.get(
        param,
        "battery_model"
      );
      _this.formData.battery_bank.no_of_block = ObjectPath.get(
        param,
        "no_of_block"
      );
      _this.formData.battery_bank.no_of_bank = ObjectPath.get(
        param,
        "no_of_bank"
      );
    });

    _this.updatePreInformation();

    setTimeout(function() {
      _this.$nextTick(() => {
        /*let ecanvas = _this.$refs["engineersignature"];
        let eparentDiv = document.getElementById("engineer-signature");
        let eparentWidth = eparentDiv.offsetWidth;
        ecanvas.setAttribute("width", eparentWidth);
        _this.engineerSignature = new SignaturePad(ecanvas);

        let ccanvas = _this.$refs["customersignature"];
        let cparentDiv = document.getElementById("customer-signature");
        let cparentWidth = cparentDiv.offsetWidth;
        ccanvas.setAttribute("width", cparentWidth);
        _this.customerSignature = new SignaturePad(ccanvas);*/
      });
    }, 1000);
  }
};
</script>
