<template>
  <v-container
    fluid
    class="white-background client-service-forms service-report-form"
    :class="{ 'pointer-events-none': serviceForm.is_filled }"
  >
    <v-row>
      <v-col md="6">
        <div class="d-none">
          <v-text-field
            type="hidden"
            v-model="pageLoading"
            :rules="[validateRules.required(!pageLoading)]"
          ></v-text-field>
        </div>
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Customer Name
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.customer_name"
                  dense
                  filled
                  label="Customer Name"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Site Location
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.site_location"
                  dense
                  filled
                  label="Site Location"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Site Address
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.site_address"
                  dense
                  filled
                  label="Site Address"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Contact Name
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.contact_name"
                  dense
                  filled
                  label="Contact Name"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Contact No
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.contact_no"
                  dense
                  filled
                  label="Contact No"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Project Code
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.project_code"
                  dense
                  filled
                  label="Project Code"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">Date</td>
              <td>
                <DatePicker
                  v-model="formData.date"
                  solo
                  :defaultDate.sync="formData.date"
                ></DatePicker>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Job/MA Plan Ref
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.job_plan_ref"
                  dense
                  filled
                  label="Job/MA Plan Ref"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Equipment
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.equipment"
                  dense
                  filled
                  label="Equipment"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Equipment ID
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.equipment_id"
                  dense
                  filled
                  label="Equipment ID"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Serial No
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.serial_no"
                  dense
                  filled
                  label="Serial No"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Product Code
              </td>
              <td>
                <v-text-field
                  v-model.trim="formData.product_code"
                  dense
                  filled
                  label="Product Code"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col md="12">
        <table class="width-100 table-bordered">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2">
                Description Of Works
              </td>
            </tr>
            <tr>
              <td>
                <v-textarea
                  v-model.trim="formData.description_of_works"
                  auto-grow
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  dense
                  filled
                  color="cyan"
                  label="Description Of Works"
                  solo
                  flat
                  row-height="25"
                ></v-textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-4">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2">
                Remarks and Parts Replacement
              </td>
            </tr>
            <tr>
              <td>
                <v-textarea
                  v-model.trim="formData.remarks_and_parts_replacement"
                  auto-grow
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  dense
                  filled
                  color="cyan"
                  label="Remarks and Parts Replacement"
                  solo
                  flat
                  row-height="25"
                ></v-textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2">
                Comments and Recommendation
              </td>
            </tr>
            <tr>
              <td>
                <v-textarea
                  v-model.trim="formData.comments_and_recommendation"
                  auto-grow
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  dense
                  filled
                  color="cyan"
                  label="Comments and Recommendation"
                  solo
                  flat
                  row-height="25"
                ></v-textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td width="200" class="font-weight-500 font-size-16 p-2">
                Arrival, Date/Time
              </td>
              <td>
                <DateTimePicker
                  v-model="formData.arrival_date_time"
                ></DateTimePicker>
              </td>
            </tr>
            <tr v-if="false">
              <td height="100" colspan="2" id="engineer-signature">
                <div class="engineer-signature custom-border-grey-dashed">
                  <canvas ref="engineersignature"></canvas>
                </div>
                <p class="mt-2 mx-2 mb-0 text-right">
                  <v-btn
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-left"
                    v-on:click="engineerSignature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  Service Engineer Name/Signature
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td width="300" class="font-weight-500 font-size-16 p-2">
                Left, Date/Time
              </td>
              <td>
                <DateTimePicker
                  v-model="formData.left_date_time"
                ></DateTimePicker>
              </td>
            </tr>
            <tr v-if="false">
              <td height="100" colspan="2" id="customer-signature">
                <div class="customer-signature custom-border-grey-dashed">
                  <canvas ref="customersignature"></canvas>
                </div>
                <p class="mt-2 mx-2 mb-0 text-right">
                  <v-btn
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-left"
                    v-on:click="customerSignature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  Customer Name/Signature
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateTimePicker from "@/view/pages/partials/DateTimePicker";
import DatePicker from "@/view/pages/partials/Datepicker";
/*import SignaturePad from "signature_pad";*/
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import ObjectPath from "object-path";

export default {
  mixins: [ValidationMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    contract: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    }
  },
  data: () => {
    return {
      pageLoading: true,
      engineerSignature: new Object(),
      customerSignature: new Object(),
      formData: new Object({
        customer_name: null,
        site_location: null,
        site_address: null,
        contact_name: null,
        contact_no: null,
        project_code: null,
        date: null,
        job_plan_ref: null,
        equipment: null,
        equipment_id: null,
        serial_no: null,
        product_code: null,
        description_of_works: null,
        remarks_and_parts_replacement: null,
        comments_and_recommendation: null,
        arrival_date_time: null,
        left_date_time: null,
        barcode: null,
        customer_signature: null,
        engineer_signature: null
      })
    };
  },
  components: {
    DatePicker,
    DateTimePicker
  },
  methods: {
    updatePreInformation() {
      const _this = this;
      try {
        if (_this.lodash.isEmpty(_this.customer) === false) {
          _this.formData.customer_name = _this.customer.display_name;
          _this.formData.site_location = _this.customer.company_name;
        }
        if (_this.lodash.isEmpty(_this.property) === false) {
          _this.formData.site_address =
            _this.property.unit_no +
            " " +
            _this.property.street_1 +
            " " +
            _this.property.street_2 +
            " " +
            _this.property.zip_code;
        }
        if (_this.lodash.isEmpty(_this.detail) === false) {
          _this.formData.contact_name = _this.detail.attention;
        }
        if (_this.lodash.isEmpty(_this.contactPerson) === false) {
          _this.formData.contact_no = _this.contactPerson.primary_phone;
        }
        if (_this.lodash.isEmpty(_this.serviceForm) === false) {
          _this.formData.barcode = _this.serviceForm.barcode;
          if (_this.lodash.isEmpty(_this.serviceForm.attributes) === false) {
            _this.formData = _this.serviceForm.attributes;
          }
        }

        const arrival_date_time = ObjectPath.get(
          _this.formData,
          "arrival_date_time"
        );
        if (!arrival_date_time) {
          _this.formData.arrival_date_time = ObjectPath.get(
            _this.serviceForm,
            "timesheet.added_at"
          );
        }

        _this.pageLoading = false;
      } catch (error) {
        _this.pageLoading = false;
      }
    },
    saveServiceForm(action, product) {
      const _this = this;

      ServiceFormEventBus.$emit("start:loader");

      let param = _this.formData;

      /*if (_this.engineerSignature) {
        param.engineer_signature = _this.engineerSignature.toDataURL();
      }
      if (_this.customerSignature) {
        param.customer_signature = _this.customerSignature.toDataURL();
      }*/

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: param, action, product }
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    }
  },
  beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
    ServiceFormEventBus.$off("update:service-form-product");
  },
  mounted() {
    const _this = this;

    ServiceFormEventBus.$on("start:save-service-form", (param, product) => {
      _this.saveServiceForm(param, product);
    });

    ServiceFormEventBus.$on("update:service-form-product", param => {
      // _this.formData.job_plan_ref = ObjectPath.get(_this.contract, "reference"); //_this.contract.reference;
      _this.formData.equipment = ObjectPath.get(param, "brand"); //param ? param.brand : null;
      _this.formData.equipment_id = ObjectPath.get(param, "name"); //param ? param.name : null;
      _this.formData.serial_no = ObjectPath.get(param, "serial_number"); //param ? param.serial_number : null;
      _this.formData.equipment_model = ObjectPath.get(param, "brand"); //param ? param.brand : null;
      _this.formData.product_code = ObjectPath.get(param, "part_number"); //param ? param.part_number : null;
    });

    _this.updatePreInformation();

    setTimeout(function() {
      _this.$nextTick(() => {
        /*let ecanvas = _this.$refs["engineersignature"];
        let eparentDiv = document.getElementById("engineer-signature");
        let eparentWidth = eparentDiv.offsetWidth;
        ecanvas.setAttribute("width", eparentWidth);
        _this.engineerSignature = new SignaturePad(ecanvas);

        let ccanvas = _this.$refs["customersignature"];
        let cparentDiv = document.getElementById("customer-signature");
        let cparentWidth = cparentDiv.offsetWidth;
        ccanvas.setAttribute("width", cparentWidth);
        _this.customerSignature = new SignaturePad(ccanvas);*/
      });
    }, 1000);
  }
};
</script>
