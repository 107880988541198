<template>
  <v-container fluid>
    <v-row class="gray-background">
      <v-col md="12">
        <v-skeleton-loader
          class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
          type="text"
          v-if="pageLoading"
        >
        </v-skeleton-loader>
        <p
          v-else
          class="m-0 font-weight-700 font-size-18 pt-3 pb-1 color-custom-blue"
        >
          {{ detail.job_title }}
          <CustomStatus
            :status.sync="detail.status"
            endpoint="quotation/status"
          ></CustomStatus>
        </p>
        <v-skeleton-loader
          class="custom-skeleton my-auto custom-skeleton-full-width mr-5"
          type="sentences"
          v-if="pageLoading"
        >
        </v-skeleton-loader>
        <template v-else>
          <div>
            <read-more
              class="custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
              more-str="read more"
              :text="detail.description ? detail.description : ''"
              link="#"
              less-str="read less"
              :max-chars="200"
            >
            </read-more>
          </div>
        </template>
      </v-col>
      <v-col md="6">
        <template v-if="pageLoading">
          <v-skeleton-loader
            v-for="(d, i) in 4"
            :key="i"
            class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
            :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <table class="width-100">
            <tr>
              <td class="font-size-18 pt-3 pb-1" width="200">Quotation Ref</td>
              <td class="font-weight-600 font-size-18 pt-3 pb-1">
                <router-link
                  :to="
                    getDefaultRoute('quotation.detail', {
                      params: { id: detail.id }
                    })
                  "
                  >{{ detail.barcode }}</router-link
                >
              </td>
            </tr>
            <tr>
              <td class="font-size-18 pb-1" width="200">Rate opportunity</td>
              <td class="font-weight-600 font-size-18 pb-1">
                <v-rating
                  readonly
                  class="remove-y-padding"
                  v-model.trim="detail.rating"
                  background-color="orange lighten-3"
                  color="orange"
                ></v-rating>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 py-1" width="200">Reference #</td>
              <td
                class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
              >
                {{ detail.reference }}
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 py-1" width="200">
                Sales representative
              </td>
              <td
                class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
              >
                {{ detail.sales }}
              </td>
            </tr>
            <tr>
              <td class="font-size-18 py-1" width="200">Prepared by</td>
              <td
                class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
              >
                {{ detail.prepared_by }}
              </td>
            </tr>
            <tr class="custom-border-top">
              <td
                class="font-size-18 py-1 custom-border-right pr-4"
                width="200"
              >
                Created date
              </td>
              <td
                class="font-size-18 px-4 py-1 custom-border-right"
                width="200"
              >
                Sent date
              </td>
              <td class="font-size-18 px-4 py-1" width="200">Open till</td>
            </tr>
            <tr>
              <td
                width="200"
                class="font-weight-600 font-size-18 py-1 pr-4 custom-border-right"
              >
                <template v-if="detail.date">{{
                  formatDate(detail.date)
                }}</template>
                <em v-else class="text--secondary">No Created Date</em>
              </td>
              <td
                width="200"
                class="font-weight-600 font-size-18 py-1 px-4 custom-border-right"
              >
                <template v-if="detail.sent_date">{{
                  formatDate(detail.sent_date)
                }}</template>
                <em v-else class="text--secondary">No Sent Date</em>
              </td>
              <td width="200" class="font-weight-600 font-size-18 py-1 px-4">
                <template v-if="detail.open_till">{{
                  formatDate(detail.open_till)
                }}</template>
                <em v-else class="text--secondary">No Open Till Date</em>
              </td>
            </tr>
          </table>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { GET } from "@/core/services/store/request.module";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
export default {
  mixins: [CommonMixin],
  components: {
    CustomStatus
  },
  props: {
    quotation: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      detail: {},
      pageLoading: false
    };
  },
  watch: {
    quotation() {
      let quotation = this.lodash.toSafeInteger(this.quotation);
      if (quotation > 0) {
        this.getQuotation();
      }
    }
  },
  methods: {
    getQuotation() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "quotation/" + _this.quotation
        })
        .then(({ data }) => {
          _this.detail = data;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }
  },
  mounted() {
    let quotation = this.lodash.toSafeInteger(this.quotation);
    if (quotation > 0) {
      this.getQuotation();
    }
  }
};
</script>
