<template>
  <DetailTemplate
    :customClass="'job-detail detail-page'"
    v-if="getPermission('job:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ detail.title }}
          </h1>
          <Priority :priority.sync="detail.priority"></Priority>
          <CustomStatus
            :status.sync="detail.status"
            endpoint="job/status"
          ></CustomStatus>
          <Allocated :allocated.sync="detail.allocated"></Allocated>
          <Scheduled :scheduled.sync="detail.scheduled"></Scheduled>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('billing:create')">
        <template v-if="jobTab == 'billing'">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                v-on:click="createInvoiceReminder"
              >
                <v-icon>mdi-bell-ring</v-icon>
              </v-btn>
            </template>
            <span>Create Invoice Reminder</span>
          </v-tooltip>
        </template>
      </template>
      <v-skeleton-loader
        class="custom-skeleton d-inline-block"
        v-if="pageLoading"
        type="button"
      >
      </v-skeleton-loader>
      <v-menu
        v-else
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 65vh; position: relative"
      > -->
      <v-container fluid>
        <v-row dense>
          <v-col cols="5" class="pb-0">
            <v-container fluid>
              <v-list flat>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                          >
                            <router-link
                              :to="
                                getDefaultRoute('customer.detail', {
                                  params: {
                                    id: customer.customer
                                  }
                                })
                              "
                              >{{ detail.attention }}</router-link
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          link
                          class="m-0 ml-4"
                          v-on:click="
                            $router.push(
                              getDefaultRoute('customer.detail', {
                                params: {
                                  id: customer.customer
                                }
                              })
                            )
                          "
                        >
                          <v-icon class="color-custom-blue"
                            >mdi-link mdi-rotate-135</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <span>Attention</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-home-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                          >
                            <router-link
                              :to="
                                getDefaultRoute('customer.detail', {
                                  params: {
                                    id: customer.customer
                                  }
                                })
                              "
                              >{{ customer.display_name }}</router-link
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          link
                          class="m-0 ml-4"
                          v-on:click="
                            $router.push(
                              getDefaultRoute('customer.detail', {
                                params: {
                                  id: customer.customer
                                }
                              })
                            )
                          "
                        >
                          <v-icon class="color-custom-blue"
                            >mdi-link mdi-rotate-135</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <span>Display Name</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                          >
                            <a
                              :href="'mailto:' + contact_person.primary_email"
                              >{{ contact_person.primary_email }}</a
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Contact Email</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                          >
                            <a :href="'tel:' + contact_person.primary_phone">{{
                              contact_person.primary_phone
                            }}</a>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Contact Phone</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else-if="property.property_address">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item class="py-1" v-bind="attrs" v-on="on">
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28"
                            >mdi-home-map-marker</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                          >
                            <a
                              target="_blank"
                              :href="
                                'https://maps.google.com/?q=' +
                                  property.property_address
                              "
                              >{{ property.property_address }}</a
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Property Address</span>
                  </v-tooltip>
                </template>
                <template v-if="billing && billing.property_address">
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="py-1" v-bind="attrs" v-on="on">
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28"
                              >mdi-home-currency-usd</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content class="p-0">
                            <v-list-item-title
                              class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                            >
                              <a
                                target="_blank"
                                :href="
                                  'https://maps.google.com/?q=' +
                                    billing.property_address
                                "
                                >{{ billing.property_address }}</a
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <span>Company Address</span>
                    </v-tooltip>
                  </template>
                </template>
              </v-list>
              <template v-if="pageLoading">
                <div class="d-flex">
                  <v-skeleton-loader
                    v-for="(d, i) in 3"
                    class="custom-skeleton height-30px mr-2 my-auto custom-skeleton-full-width"
                    width="100"
                    type="text"
                    :key="i"
                  >
                  </v-skeleton-loader>
                </div>
              </template>
              <template v-else>
                <template v-for="(type, index) in detail.ticket_type">
                  <v-tooltip
                    :key="'ticket-type' + index"
                    top
                    content-class="custom-top-tooltip"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        class="ma-2 font-weight-600"
                        color="chip-custom-blue"
                        label
                        outlined
                      >
                        {{ type }}
                      </v-chip>
                    </template>
                    <span>Job Type</span>
                  </v-tooltip>
                </template>
              </template>
            </v-container>
          </v-col>
          <v-col cols="7" class="pb-0 gray-background pl-4">
            <v-container fluid>
              <v-row dense>
                <v-col md="12">
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <p
                    v-else
                    class="m-0 font-weight-700 font-size-18 pt-3 pb-1 color-custom-blue"
                    v-on:dblclick="updateField('title')"
                  >
                    <template v-if="updateDetailDialog.title">
                      <v-text-field
                        ref="title"
                        v-model.trim="updateDetail.title"
                        dense
                        filled
                        color="cyan"
                        label="Job Title"
                        :rules="[
                          validateRules.minLength(
                            updateDetail.title,
                            'Job Title',
                            1
                          ),
                          validateRules.maxLength(
                            updateDetail.title,
                            'Job Title',
                            100
                          )
                        ]"
                        solo
                        flat
                        hide-details
                        :loading="particuleFieldLoading"
                        :disabled="particuleFieldLoading"
                        @keydown.esc="onEscParticularField"
                        @keydown.enter="updateParticularField"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      <span
                        :class="{
                          'custom-bottom-border': canUpdateJob
                        }"
                      >
                        <template v-if="detail.title">{{
                          detail.title
                        }}</template>
                        <template v-else
                          ><em class="text--secondary font-weight-500"
                            >No Job Title</em
                          ></template
                        >
                      </span>
                      <UpdateTooltip v-if="canUpdateJob"></UpdateTooltip>
                    </template>
                  </p>
                  <v-skeleton-loader
                    class="custom-skeleton my-auto custom-skeleton-full-width mr-5"
                    type="sentences"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <template v-if="updateDetailDialog.description">
                      <v-textarea
                        ref="description"
                        auto-grow
                        v-model.trim="updateDetail.description"
                        dense
                        filled
                        color="cyan"
                        label="Description"
                        :rules="[
                          validateRules.minLength(
                            updateDetail.description,
                            'Description',
                            1
                          ),
                          validateRules.maxLength(
                            updateDetail.description,
                            'Description',
                            1024
                          )
                        ]"
                        solo
                        flat
                        hide-details
                        row-height="25"
                        :loading="particuleFieldLoading"
                        :disabled="particuleFieldLoading"
                        @keydown.esc="onEscParticularField"
                        @keydown.enter="updateParticularField"
                      ></v-textarea>
                    </template>
                    <template v-else>
                      <div
                        class="d-flex align-start"
                        v-on:dblclick="updateField('description')"
                      >
                        <read-more
                          class="custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          :class="{
                            'custom-bottom-border': canUpdateJob
                          }"
                          more-str="read more"
                          :text="
                            detail.description || '<em>No Description</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                        <UpdateTooltip v-if="canUpdateJob"></UpdateTooltip>
                      </div>
                    </template>
                  </template>
                </v-col>
                <v-col md="12">
                  <v-skeleton-loader
                    class="custom-skeleton height-30px mr-2 my-auto custom-skeleton-full-width"
                    width="100"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <v-chip
                    v-else
                    class="my-2 font-weight-600"
                    color="chip-custom-blue"
                    label
                    outlined
                  >
                    {{ getScheduleType }}
                  </v-chip>
                  <template v-if="pageLoading">
                    <v-skeleton-loader
                      v-for="(d, i) in 4"
                      :key="i"
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <table class="width-100">
                      <tr>
                        <td class="font-size-18 pt-3 pb-1" width="200">
                          Job #
                        </td>
                        <td class="font-weight-600 font-size-18 pt-3 pb-1">
                          {{ detail.barcode }}
                        </td>
                      </tr>
                      <tr v-if="detail.project">
                        <td class="font-size-18 py-1" width="200">Project #</td>
                        <td class="font-weight-600 font-size-18 py-1">
                          <router-link
                            :to="
                              getDefaultRoute('project.detail', {
                                params: {
                                  id: detail.project.id
                                }
                              })
                            "
                            >{{ detail.project.barcode }}</router-link
                          >
                        </td>
                      </tr>
                      <tr v-if="detail.soled_job_no">
                        <td class="font-size-18 py-1" width="200">
                          SOled Job #
                        </td>
                        <td class="font-weight-600 font-size-18 py-1">
                          {{ detail.soled_job_no }}
                        </td>
                      </tr>
                      <tr v-if="detail.contract">
                        <td class="font-size-18 py-1" width="200">
                          Contract #
                        </td>
                        <td class="font-weight-600 font-size-18 py-1">
                          <router-link
                            :to="
                              getDefaultRoute('contract.detail', {
                                params: {
                                  id: detail.contract.id
                                }
                              })
                            "
                            >{{ detail.contract.barcode }}</router-link
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Reference #
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                          v-on:dblclick="updateField('reference')"
                        >
                          <template v-if="updateDetailDialog.reference">
                            <v-text-field
                              ref="reference"
                              v-model.trim="updateDetail.reference"
                              dense
                              filled
                              color="cyan"
                              label="Reference #"
                              :rules="[
                                validateRules.minLength(
                                  updateDetail.reference,
                                  'Reference',
                                  1
                                ),
                                validateRules.maxLength(
                                  updateDetail.reference,
                                  'Reference',
                                  100
                                )
                              ]"
                              solo
                              flat
                              hide-details
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateJob
                              }"
                            >
                              <template v-if="detail.reference">{{
                                detail.reference
                              }}</template>
                              <template v-else
                                ><em class="text--secondary font-weight-500"
                                  >No Reference</em
                                ></template
                              >
                            </span>
                            <UpdateTooltip v-if="canUpdateJob"></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          {{ StartedOn }}
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          {{ formatDateTime(detail.started_at) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">Lasts For</td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="m-0 max-content-width"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ detail.lasts_for }}
                              </p>
                            </template>
                            <span>{{
                              formatDateTime(detail.finished_at)
                            }}</span>
                          </v-tooltip>
                        </td>
                      </tr>
                      <tr v-if="detail.type === 2">
                        <td class="font-size-18 py-1" width="200">
                          Billing frequency
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          {{ getInvoiceDuration }}
                        </td>
                      </tr>
                      <tr v-if="detail.type === 2">
                        <td class="font-size-18 py-1" width="200">
                          Billing type
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          {{ getBillingType }}
                        </td>
                      </tr>
                      <tr v-if="detail.type === 2">
                        <td class="font-size-18 py-1" width="200">Schedule</td>
                        <td class="font-weight-600 font-size-18 py-1">
                          <v-skeleton-loader
                            class="custom-skeleton my-auto custom-skeleton-full-width"
                            type="sentences"
                            v-if="recurrenceLoading"
                          >
                          </v-skeleton-loader>
                          <read-more
                            v-else
                            class="custom-read-more font-weight-600 font-size-16 pb-1 pr-2 custom-gray-color"
                            more-str="read more"
                            :text="getVisitScheduleType"
                            link="#"
                            less-str="read less"
                            :max-chars="60"
                          >
                          </read-more>
                        </td>
                      </tr>
                    </table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" class="mt-8">
            <v-tabs
              active-class="custom-tab-active"
              v-model="jobTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
            >
              <template v-if="getPermission('job:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#other'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/custom-svg/job.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  Overview
                </v-tab>
              </template>
              <template v-if="getPermission('visit:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#visit'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.visit_count"
                    bordered
                    color="orange darken-4"
                    :content="detail.visit_count"
                  >
                    {{ detail.visit_count > 1 ? "Visits" : "Visit" }}
                  </v-badge>
                  <template v-else> Visit </template>
                </v-tab>
              </template>
              <template v-if="getPermission('line-item:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#line-item'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.line_items_count"
                    bordered
                    color="orange darken-4"
                    :content="detail.line_items_count"
                  >
                    {{
                      detail.line_items_count > 1 ? "Line Items" : "Line Item"
                    }}
                  </v-badge>
                  <template v-else> Line Item </template>
                </v-tab>
              </template>
              <template v-if="getPermission('visit-team:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#team'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/engineer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.engineer_count"
                    bordered
                    color="orange darken-4"
                    :content="detail.engineer_count"
                  >
                    Team
                  </v-badge>
                  <template v-else> Team </template>
                </v-tab>
              </template>
              <template v-if="getPermission('billing:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#billing'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/billing.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.invoice_reminders_count > 0"
                    bordered
                    color="orange darken-4"
                    :content="detail.invoice_reminders_count"
                  >
                    Billing
                  </v-badge>
                  <template v-else> Billing </template>
                </v-tab>
              </template>
              <template v-if="getPermission('service-form:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#service-form'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/service-form.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.service_forms_count > 0"
                    bordered
                    color="orange darken-4"
                    :content="detail.service_forms_count"
                  >
                    {{
                      detail.service_forms_count > 1
                        ? "Service Forms"
                        : "Service Form"
                    }}
                  </v-badge>
                  <template v-else> Service Form </template>
                </v-tab>
              </template>
              <template v-if="getPermission('timesheet:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#time-sheet'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/timesheet.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.time_sheets_count > 0"
                    bordered
                    color="orange darken-4"
                    :content="detail.time_sheets_count"
                  >
                    Timesheet
                  </v-badge>
                  <template v-else> Timesheet </template>
                </v-tab>
              </template>
              <template v-if="getPermission('closer:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#close-detail'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/closer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.closers_count > 0"
                    bordered
                    color="orange darken-4"
                    :content="detail.closers_count"
                  >
                    Closer
                  </v-badge>
                  <template v-else> Closer </template>
                </v-tab>
              </template>
              <template v-if="getPermission('attachment:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#attachment'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/attachment.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="attachmentTotal > 0"
                    bordered
                    color="orange darken-4"
                    :content="attachmentTotal"
                  >
                    Attachments
                  </v-badge>
                  <template v-else> Attachments </template>
                </v-tab>
              </template>
              <template v-if="getPermission('quotation:view')">
                <v-tab
                  v-if="quotationId > 0"
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#quotation'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/quotation.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Quotation
                </v-tab>
              </template>
              <template v-if="getPermission('invoice:view')">
                <v-tab
                  v-if="invoiceId > 0"
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#invoice'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/invoice.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Invoice
                </v-tab>
              </template>
              <template v-if="getPermission('history:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  :href="'#history'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </template>
            </v-tabs>
            <v-tabs-items v-model="jobTab">
              <template v-if="getPermission('visit:view')">
                <v-tab-item :value="'visit'">
                  <VisitDetail
                    :detail.sync="detail"
                    :customer.sync="customer"
                    :property.sync="property"
                    :billing.sync="billing"
                    :contactPerson.sync="contact_person"
                    :dailyRecurrence.sync="daily_recurrence"
                    :monthlyRecurrence.sync="monthly_recurrence"
                    :weeklyRecurrence.sync="weekly_recurrence"
                    :pageLoading.sync="pageLoading"
                  ></VisitDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('line-item:view')">
                <v-tab-item :value="'line-item'">
                  <LineItemDetail
                    isTicket
                    type="job"
                    :detail.sync="detail"
                    :pageLoading.sync="pageLoading"
                    v-on:load:parent="loadContent"
                  ></LineItemDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('visit-team:view')">
                <v-tab-item :value="'team'">
                  <TeamDetail
                    :detail.sync="detail"
                    :pageLoading.sync="pageLoading"
                  ></TeamDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('billing:view')">
                <v-tab-item :value="'billing'">
                  <InvoiceReminderDetail
                    :detail.sync="detail"
                    :customer.sync="customer"
                    :property.sync="property"
                    :billing.sync="billing"
                    :contactPerson.sync="contact_person"
                    :pageLoading.sync="pageLoading"
                  ></InvoiceReminderDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('service-form:view')">
                <v-tab-item :value="'service-form'">
                  <ServiceFormDetail
                    :detail.sync="detail"
                    :customer.sync="customer"
                    :property.sync="property"
                    :billing.sync="billing"
                    :contactPerson.sync="contact_person"
                    :pageLoading.sync="pageLoading"
                  ></ServiceFormDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('timesheet:view')">
                <v-tab-item :value="'time-sheet'">
                  <TimeTrackingDetail
                    :detail.sync="detail"
                    :pageLoading.sync="pageLoading"
                    :customer.sync="customer"
                    :property.sync="property"
                    :billing.sync="billing"
                    :contactPerson.sync="contact_person"
                  ></TimeTrackingDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('closer:view')">
                <v-tab-item :value="'close-detail'">
                  <TicketCloserDetail
                    :detail.sync="detail"
                    :customer.sync="customer"
                    :property.sync="property"
                    :billing.sync="billing"
                    :contactPerson.sync="contact_person"
                    :pageLoading.sync="pageLoading"
                  ></TicketCloserDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('attachment:view')">
                <v-tab-item :value="'attachment'">
                  <TicketAttachmentDetail
                    :detail.sync="detail"
                    :pageLoading.sync="pageLoading"
                    v-on:total:attachment="updateAttachmentTotal"
                  ></TicketAttachmentDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('job:view')">
                <v-tab-item :value="'other'">
                  <LineItemOnlyView
                    isTicket
                    type="job"
                    :detail.sync="detail"
                    :pageLoading.sync="pageLoading"
                  ></LineItemOnlyView>
                  <TermConditionDetail
                    type="job"
                    :detail.sync="detail"
                  ></TermConditionDetail>
                  <InternalNoteAttachmentDetail
                    type="job"
                    :detail.sync="detail"
                    isTicket
                  ></InternalNoteAttachmentDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('quotation:view')">
                <v-tab-item v-if="quotationId > 0" :value="'quotation'">
                  <InternalQuotationDetail
                    :quotation.sync="quotationId"
                  ></InternalQuotationDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('invoice:view')">
                <v-tab-item v-if="invoiceId > 0" :value="'invoice'">
                  <InternalInvoiceDetail
                    :invoice.sync="invoiceId"
                  ></InternalInvoiceDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('history:view')">
                <v-tab-item :value="'history'">
                  <InternalHistoryDetail
                    type="job"
                    :type_id.sync="detail.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <!-- </perfect-scrollbar> -->
    </template>
    <template v-slot:footer>
      <template v-if="getPermission('billing:create')">
        <template v-if="invoiceReminderDialog">
          <CreateInvoiceReminder
            :invoiceDialog.sync="invoiceReminderDialog"
            :detail.sync="detail"
            :teams.sync="teams"
            :customer.sync="customer"
            :property.sync="property"
            :billing.sync="billing"
            :contactPerson.sync="contact_person"
            :pageLoading.sync="pageLoading"
            v-on:create:invoice-reminder="createdInvoiceReminder"
            v-on:close:invoice-reminder="invoiceReminderDialog = false"
          >
          </CreateInvoiceReminder>
        </template>
      </template>
    </template>
  </DetailTemplate>
</template>

<script>
import DetailJobMixin from "@/core/lib/job/detail.job.mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import Priority from "@/view/pages/partials/Priority.vue";
import TermConditionDetail from "@/view/pages/partials/Detail/Term-Condition-Detail.vue";
import LineItemOnlyView from "@/view/pages/partials/Line-Item-Only-View.vue";
import InternalNoteAttachmentDetail from "@/view/pages/partials/Detail/Internal-Note-Attachment-Detail.vue";
import LineItemDetail from "@/view/pages/partials/Detail/Line-Item-Detail.vue";
import VisitDetail from "@/view/pages/job/partials/Visit-Detail.vue";
import TeamDetail from "@/view/pages/job/partials/Team-Detail.vue";
import InvoiceReminderDetail from "@/view/pages/job/partials/Invoice-Reminder-Detail.vue";
import ServiceFormDetail from "@/view/pages/job/partials/Service-Form-Detail.vue";
import TimeTrackingDetail from "@/view/pages/job/partials/Time-Tracking-Detail.vue";
import TicketCloserDetail from "@/view/pages/job/partials/Ticket-Closer-Detail.vue";
import TicketAttachmentDetail from "@/view/pages/job/partials/Ticket-Attachment-Detail.vue";
import CreateInvoiceReminder from "@/view/pages/job/partials/Create-Invoice-Reminder.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import InternalQuotationDetail from "@/view/pages/partials/Detail/Internal-Quotation-Detail.vue";
import InternalInvoiceDetail from "@/view/pages/partials/Detail/Internal-Invoice-Detail.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import UpdateTooltip from "@/view/pages/partials/UpdateTooltip.vue";
import Allocated from "@/view/pages/partials/Allocated.vue";
import Scheduled from "@/view/pages/partials/Scheduled.vue";

export default {
  mixins: [DetailJobMixin],
  components: {
    UpdateTooltip,
    DetailTemplate,
    Priority,
    Allocated,
    Scheduled,
    TermConditionDetail,
    LineItemOnlyView,
    InternalNoteAttachmentDetail,
    LineItemDetail,
    VisitDetail,
    TeamDetail,
    InvoiceReminderDetail,
    ServiceFormDetail,
    TimeTrackingDetail,
    TicketCloserDetail,
    TicketAttachmentDetail,
    CreateInvoiceReminder,
    CustomStatus,
    InternalQuotationDetail,
    InternalInvoiceDetail,
    InternalHistoryDetail
  }
};
</script>
