<template>
  <div class="internal-notes-attachment-detail">
    <v-container
      fluid
      :class="{
        'pointer-events-none': !getPermission(type + ':update')
      }"
    >
      <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <h3 class="font-weight-700 custom-headline color-custom-blue">
            Internal Notes &amp; Attachments
          </h3>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <v-row>
            <v-col v-if="!isPurchaseOrder" cols="6" class="pt-0">
              <h4
                class="color-custom-blue text-uppercase font-size-16 font-weight-600"
              >
                Client Notes
              </h4>
              <div v-on:dblclick="updateTemplate('client')">
                <editor
                  v-model="clientNotes"
                  :disabled="formLoading || !updateClientDialog"
                />
              </div>
              <div
                v-if="updateClientDialog"
                class="text-right custom-border-top py-4 mt-4"
              >
                <v-btn
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  :loading="formLoading"
                  :disabled="formLoading"
                  v-on:click="updateClientNotes"
                >
                  Save
                </v-btn>
                <v-btn
                  :disabled="formLoading"
                  class="custom-grey-border custom-bold-button"
                  v-on:click="updateClientDialog = false"
                >
                  Cancel
                </v-btn>
              </div>
            </v-col>
            <v-col :cols="isPurchaseOrder ? 12 : 6" class="pt-0">
              <h4
                class="color-custom-blue text-uppercase font-size-16 font-weight-600"
              >
                {{ isPurchaseOrder ? "Internal Notes" : "Admin Notes" }}
              </h4>
              <div v-on:dblclick="updateTemplate('admin')">
                <editor
                  v-model="adminNotes"
                  :disabled="formLoading || !updateAdminDialog"
                />
              </div>
              <div
                v-if="updateAdminDialog"
                class="text-right custom-border-top py-4 mt-4"
              >
                <v-btn
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  :loading="formLoading"
                  :disabled="formLoading"
                  v-on:click="updateAdminNotes"
                >
                  Save
                </v-btn>
                <v-btn
                  :disabled="formLoading"
                  class="custom-grey-border custom-bold-button"
                  v-on:click="updateAdminDialog = false"
                >
                  Cancel
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <FileTemplate
            :attachments.sync="detail.documents"
            :allowDelete.sync="canUpdate"
          ></FileTemplate>
          <div
            v-if="!isPayment && !isPurchaseOrder && !isProposal"
            class="mt-4 custom-border-top"
          >
            <h4
              class="color-custom-blue text-uppercase font-size-16 font-weight-600 pt-4 custom-border-top"
            >
              Email Notifications
            </h4>
            <div
              class="d-flex"
              :class="{
                'pointer-events-none': !getPermission(type + ':update')
              }"
            >
              <v-switch
                v-model="notifyAdmin"
                inset
                label="Admin"
                color="cyan"
                class="mx-3"
                :readonly="!canUpdate"
                :disabled="formLoading"
                v-on:change="updateNotifications('notify_admin')"
              ></v-switch>
              <v-switch
                v-model="notifyCustomer"
                inset
                label="Client"
                color="cyan"
                class="mx-3"
                :readonly="!canUpdate"
                :disabled="formLoading"
                v-on:change="updateNotifications('notify_customer')"
              ></v-switch>
              <v-switch
                v-if="false"
                v-model="notifyEngineer"
                inset
                label="Engineer"
                color="cyan"
                class="mx-3"
                :readonly="!canUpdate"
                :disabled="formLoading"
                v-on:change="updateNotifications('notify_engineer')"
              ></v-switch>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import { PATCH } from "@/core/services/store/request.module";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  mixins: [FileManagerMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    type: {
      required: true,
      type: String,
      default: "job"
    },
    canUpdate: {
      type: Boolean,
      default: true
    },
    isTicket: {
      type: Boolean,
      default: false
    },
    isPurchaseOrder: {
      type: Boolean,
      default: false
    },
    isProposal: {
      type: Boolean,
      default: false
    },
    isPayment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      updateAdminDialog: false,
      updateClientDialog: false,
      formLoading: false,
      notifyAdmin: false,
      notifyCustomer: false,
      notifyEngineer: false,
      adminNotes: null,
      clientNotes: null
    };
  },
  components: {
    FileTemplate,
    editor: TinyMCE
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.adminNotes = param.admin_remark;
        this.clientNotes = param.client_remark;
        this.notifyAdmin = param.notify_admin;
        this.notifyCustomer = param.notify_customer;
        this.notifyEngineer = param.notify_engineer;
      }
    }
  },
  methods: {
    updateTemplate(param) {
      if (this.canUpdate && this.getPermission(this.type + ":update")) {
        if (param == "admin") {
          this.updateAdminDialog = true;
        } else if (param == "client") {
          this.updateClientDialog = true;
        }
      }
    },
    patchDetail(parent, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (parent) {
          let APIUrl = this.type == "proposal" ? "proposals" : this.type;
          _this.$store
            .dispatch(PATCH, { url: APIUrl + "/" + parent, data })
            .then(({ data }) => {
              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          reject("Parent is required");
        }
      });
    },
    updateAdminNotes() {
      const _this = this;
      if (_this.canUpdate && _this.getPermission(_this.type + ":update")) {
        _this.formLoading = true;
        _this
          .patchDetail(_this.detail.id, { admin_remark: _this.adminNotes })
          .then(() => {
            _this.updateAdminDialog = false;
          })
          .catch(error => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
    updateClientNotes() {
      const _this = this;
      if (_this.canUpdate && _this.getPermission(_this.type + ":update")) {
        _this.formLoading = true;
        _this
          .patchDetail(_this.detail.id, { client_remark: _this.clientNotes })
          .then(() => {
            _this.updateClientDialog = false;
          })
          .catch(error => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
    updateNotifications(query) {
      const _this = this;
      if (_this.canUpdate && _this.getPermission(_this.type + ":update")) {
        _this.formLoading = true;
        let formData = {};
        if (query == "notify_admin") {
          formData = { notify_admin: +_this.notifyAdmin };
        }
        if (query == "notify_customer") {
          formData = { notify_customer: +_this.notifyCustomer };
        }
        if (query == "notify_engineer") {
          formData = { notify_engineer: +_this.notifyEngineer };
        }
        _this
          .patchDetail(_this.detail.id, formData)
          .catch(error => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    }
  },
  mounted() {
    this.adminNotes = this.detail.admin_remark;
    this.clientNotes = this.detail.client_remark;
    this.notifyAdmin = this.detail.notify_admin;
    this.notifyCustomer = this.detail.notify_customer;
    this.notifyEngineer = this.detail.notify_engineer;
  }
};
</script>
