<template>
  <Dialog :commonDialog="visitEngineerDialog">
    <template v-slot:title
      >Add Engineer
      <v-icon small class="mx-4 color-custom-blue"
        >mdi-checkbox-blank-circle</v-icon
      >
      Job# {{ detail.barcode }}</template
    >
    <template v-slot:body>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 60vh; position: relative"
      > -->
      <v-form
        ref="visitTeamForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent
      >
        <v-container>
          <h5 class="font-weight-600 pb-4">
            Select those engineer(s) which you want to add this visit.
          </h5>
          <v-layout class="justify-center">
            <v-text-field
              ref="search-engineer"
              v-model.trim="engineer"
              :loading="searchLoader"
              :background-color="!isFocused ? 'grey lighten-3' : undefined"
              :flat="!isFocused && !isSearching"
              :placeholder="placeholder"
              autocomplete="off"
              class="rounded-lg"
              dense
              hide-details
              solo
              color="cyan"
              @blur="isFocused = false"
              @focus="isFocused = true"
              @keydown.esc="onEsc"
              @keydown.enter="onEnter"
              @keydown.tab="onTab"
            >
              <template #prepend-inner>
                <v-icon
                  :color="!isFocused ? 'grey' : undefined"
                  class="ml-1 mr-2"
                >
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-layout>
          <v-row dense>
            <v-col class="pt-4 pb-0" cols="12">
              <!-- <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="scroll"
                  style="max-height: 60vh; position: relative"
                > -->
              <v-list subheader three-line>
                <v-skeleton-loader
                  v-if="searchLoader"
                  class="custom-skeleton"
                  type="list-item-two-line"
                >
                </v-skeleton-loader>
                <template v-else>
                  <template v-if="engineers.length > 0">
                    <v-list-item
                      v-for="(data, index) in engineers"
                      :key="index"
                      link
                      class="rounded-lg"
                      v-on:click="selectEngineer(data.id)"
                      :class="{
                        'amber lighten-4': lodash.includes(
                          selectedEngineers,
                          data.id
                        )
                      }"
                    >
                      <v-list-item-avatar>
                        <v-img
                          :lazy-src="$defaultProfileImage"
                          :src="$assetAPIURL(data.profile_logo)"
                          aspect-ratio="1"
                          class="margin-auto grey lighten-5 custom-grey-border"
                          transition="fade-transition"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >#{{ data.barcode }}</v-list-item-title
                        >
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ data.display_name }}</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action class="margin-auto">
                        <v-list-item-subtitle
                          class="text-lowercase font-weight-500 mb-2 font-size-14"
                          >{{ data.primary_email }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                          class="text-lowercase font-weight-500 font-size-14"
                          >{{ data.primary_phone }}</v-list-item-subtitle
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-else>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-500 font-size-16"
                          >No Engineer(s) Found</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </template>
              </v-list>
              <!-- </perfect-scrollbar> -->
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <!-- </perfect-scrollbar> -->
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate"
      >
        Save
      </v-btn>
      <v-btn
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="closeDialog"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import moment from "moment-timezone";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { QUERY, PUT } from "@/core/services/store/request.module";
import {
  ClearEventBus,
  ErrorEventBus,
  InitializeError
} from "@/core/lib/message.lib";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  props: {
    detail: {
      type: Object,
      default() {
        return new Object();
      }
    },
    visit: {
      type: Object,
      default() {
        return new Object();
      }
    },
    pageLoading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    engineer(param) {
      if (!param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function() {
          _this.getAvailableEngineer();
        }, _this.timeoutLimit);
      }
    }
  },
  data() {
    return {
      isFocused: false,
      searchLoader: true,
      engineer: null,
      timeoutLimit: 500,
      timeout: null,
      force: false,
      all: true,
      visitEngineerDialog: true,
      formValid: true,
      formLoading: false,
      selectedEngineers: new Array(),
      engineers: new Array()
    };
  },
  methods: {
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      if (this.isSearching) {
        this.getAvailableEngineer();
      }
    },
    onTab() {
      if (this.isSearching) {
        this.getAvailableEngineer();
      }
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.engineer = null;
        _this.isFocused = false;
      });
    },
    selectEngineer(id) {
      if (this.lodash.includes(this.selectedEngineers, id)) {
        let index = this.selectedEngineers.indexOf(id);
        this.selectedEngineers.splice(index, 1);
      } else {
        this.selectedEngineers.push(id);
      }
    },
    updateOrCreate() {
      const _this = this;

      if (_this.selectedEngineers.length > 0) {
        _this.formLoading = true;
        _this.$store
          .dispatch(PUT, {
            url:
              "job/" +
              _this.detail.id +
              "/visit/" +
              _this.visit.id +
              "/engineer",
            data: {
              engineer: _this.selectedEngineers
            }
          })
          .then(() => {
            DialogJobEventBus.$emit("load:job", true);
            DialogJobEventBus.$emit("load:visit", true);
            DialogJobEventBus.$emit("load:visit-content", true);
            DialogJobEventBus.$emit("close:visit-team-dialog", true);
          })
          .catch(error => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } else {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select atleast one engineer.")
        );
      }
    },
    getAvailableEngineer() {
      const _this = this;
      ClearEventBus.$emit("clear:success");
      if (moment(_this.visit.started_at).isAfter(_this.visit.finished_at)) {
        _this.searchLoader = false;
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Start date & time must before End date & time.")
        );
        return false;
      }
      _this.engineers = [];
      _this.searchLoader = true;
      _this.$store
        .dispatch(QUERY, {
          url: "job/available-engineers",
          data: {
            search: _this.engineer,
            ticket: _this.detail.id,
            start_date: _this.visit.started_at
              ? moment(_this.visit.started_at).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            start_time: _this.visit.started_at
              ? moment(_this.visit.started_at).format("HH:mm")
              : moment()
                  .startOf("day")
                  .format("HH:mm"),
            end_date: _this.visit.finished_at
              ? moment(_this.visit.finished_at).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            end_time: _this.visit.finished_at
              ? moment(_this.visit.finished_at).format("HH:mm")
              : moment()
                  .endOf("day")
                  .format("HH:mm"),
            force: +_this.force
          }
        })
        .then(({ data }) => {
          _this.engineers = data.engineers;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.searchLoader = false;
        });
    },
    closeDialog() {
      DialogJobEventBus.$emit("close:visit-team-dialog", true);
    }
  },
  components: {
    Dialog
  },
  mounted() {
    this.getAvailableEngineer();
  },
  computed: {
    isSearching() {
      return this.engineer && this.engineer.length > 0;
    },
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search Engineer...";
    }
  }
};
</script>
