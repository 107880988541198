import { PATCH } from "@/core/services/store/request.module";

export default {
  methods: {
    patchJob(job, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (job) {
          _this.$store
            .dispatch(PATCH, { url: "job/" + job, data })
            .then(({ data }) => {
              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          reject("Job is required");
        }
      });
    }
  }
};
